<template>
  <div v-loading="loading" class="resource-paper">
    <div class="left-box">
      <div class="menu">
        <div class="menu-list" @click="uploadExamPaperDialog">
          <img src="@/assets/resourse/paper/icon_up.png" alt="" />
          上传试卷
        </div>
        <div
          v-for="(menu, index) in menuList"
          :key="index"
          class="menu-list"
          :class="{ on: menu.isClick }"
          @click="menuClick(menu)"
        >
          <img :src="menu.img" alt="" />
          <span>{{ menu.name }}</span>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="filter-box">
        <div class="row">
          <div class="item">
            <div class="label">教材：</div>
            <el-cascader
              v-model="materialIds"
              :options="teachMaterialData"
              clearable
              @change="materialChange"
            ></el-cascader>
          </div>
          <div class="item">
            <div class="label">章节：</div>
            <el-cascader
              v-model="chapterIds"
              :options="getbookchapterList"
              :props="defaultProps"
              clearable
              @change="chapterIdsChange"
            ></el-cascader>
          </div>
        </div>
        <div class="radios">
          <div class="label">年级：</div>
          <div
            v-for="(item, index) in gradeOptionsCopy"
            :key="index"
            :class="['radio', gradeId == item.value ? 'active' : '']"
            @click="gradeIdCliCK(item)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="radios">
          <div class="label">年份：</div>
          <div
            v-for="(item, index) in yearOptions"
            :key="index"
            :class="['radio', year == item.value ? 'active' : '']"
            @click="yearClick(item)"
          >
            {{ item.label }}
          </div>
        </div>
        <div class="input">
          搜索：<el-input
            v-model="keyword"
            clearable
            placeholder="请输入作业卡名称"
            @keyup.enter.native="search"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="search"
            >
            </i>
          </el-input>
        </div>
      </div>
      <div class="homework-list">
        <template v-if="tableData.length">
          <div v-for="(item, index) in tableData" :key="index" class="homework">
            <div class="homework-title" @click="toEdit(item)">
              <img
                src="@/static/homework/all job card_icon12@1x.png"
                alt=""
              />{{ item.name }}
            </div>
            <div class="footer">
              <div class="message">
                <div class="item">年级： {{ item.gradeName }}</div>
                <div class="item">题数：{{ item.sheetDetailCount }}</div>
                <div class="item">创建人：{{ item.createName || "-" }}</div>
                <div class="item">时间：{{ item.createTime }}</div>
              </div>
              <div class="btns">
                <distributeWork
                  :disabled="item.status != 1"
                  has-icon
                  :item-data="item"
                />
                <el-button @click="cancelShareItem(item)">
                  {{ item.isShare == 1 ? "取消共享" : "共享到校本" }}
                </el-button>
                <el-button @click="deleteItem(item)"> 删除 </el-button>
              </div>
            </div>
          </div>
        </template>
        <no-data v-else style="margin: 30px 0" message="暂无数据"></no-data>
        <el-pagination
          :current-page="current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <paperForm ref="paperForm"></paperForm>
  </div>
</template>

<script>
import distributeWork from "@/components/resource/distributeWork";
import {
  resPage,
  deleteMyHomework,
  cancleShare,
} from "@/core/api/homework/index";
import { getTeachingBooksList } from "@/core/api/resource/myResource";
import { getbookchapterbyid } from "@/core/api/resource/knowledgePointsPaper";
import {
  gradeHighOptions,
  gradeMiddleOptions,
  primaryOptions,
} from "@/core/util/constdata";
import { levelyear } from "@/core/api/homework/index";
import { filterSubject } from "@/core/util/util";
// import { getStore } from "@/core/util/store";
import paperForm from "../../resource/myResource/components/paperForm.vue";
import { mapGetters } from "vuex";
export default {
  name: "Myresource",
  components: {
    distributeWork,
    paperForm,
  },
  data() {
    return {
      sourceTypeItem: [
        {
          name: "小考真题",
          value: 11,
          level: 1,
        },
        {
          name: "中考真题",
          value: 12,
          level: 2,
        },
        {
          name: "高考真题",
          value: 13,
          level: 3,
        },
        {
          name: "名校资源",
          value: 1,
        },
        {
          name: "常规资源",
          value: 0,
        },
      ],
      menuList: [
        {
          name: "我的收藏",
          type: 1,
          isClick: false,
          img: require("@/assets/resourse/paper/icon_collect.png"),
        },
        {
          name: "我的组卷",
          type: 2,
          isClick: false,
          img: require("@/assets/resourse/paper/icon_paper.png"),
        },
        {
          name: "我的上传",
          type: 3,
          isClick: false,
          img: require("@/assets/resourse/paper/icon_my_up.png"),
        },
        {
          name: "我的作业卡",
          type: 4,
          isClick: true,
          img: require("@/static/homework/my homework card_icon16@1x.png"),
        },
      ],
      subjectOptions: filterSubject(),
      materialId: null,
      materialIds: [],
      current: 1,
      pageSize: 20,
      year: -1,
      gradeId: null,
      total: 0,
      tableData: [],
      yearOptions: [],
      gradeOptionsCopy: [],
      teachMaterialData: [],
      bookInfo: [],
      bookInfoId: null,
      keyword: "",
      loading: false,
      gradeName: "",
      getbookchapterList: [],
      chapterIds: "",
      chapterId: "",
      defaultProps: {
        children: "children",
        label: "name",
        value: "id",
        checkStrictly: true,
      },
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  mounted() {
    this.getData();
    this.onloadList();
    this.getgetTeachingBooksList();
    this.getlevelyear();
    const storage = localStorage.getItem("jzjx-schoolInfo");
    const schoolInfo = storage ? JSON.parse(storage).content : {};
    const application = schoolInfo.application || "";
    const hasHomework = application.indexOf("221") != -1;
    if (!hasHomework) {
      this.menuList = this.menuList.filter((item) => item.name != "我的作业卡");
    }
  },
  methods: {
    chapterIdsChange() {
      // if (this.chapterIds.length) {
      // this.chapterId = this.chapterIds[this.chapterIds.length - 1];
      this.getData();
      // } else {
      //   this.chapterId = "";
      //   this.getData();
      // }
    },
    async getlevelyear() {
      const res = await levelyear();
      const result = res.data.data.every((item) => item.level == -1); // 等于-1的时候为管理员 客服账号 考务账号
      if (result) {
        const nowYear = new Date().getFullYear();
        const arr = new Array(4).fill("");
        let yearOptions = arr.map((item, index) => {
          return { label: nowYear - index, value: nowYear - index };
        });
        this.yearOptions = [
          { label: "全部", value: -1 },
          ...yearOptions,
          { label: "更早", value: -2 },
        ];
      } else {
        const arr = res.data.data
          .filter((item) => item.level == this.getLevelId)
          .flatMap((item) => item.yearList);
        const result = [...new Set(arr)].map((item) => ({
          label: item,
          value: item,
        }));
        this.yearOptions = [{ label: "全部", value: -1 }, ...result];
        if (arr.length >= 5) {
          this.yearOptions.push({ label: "更早", value: -2 });
        }
      }
    },
    toEdit(item) {
      const { href } = this.$router.resolve({
        path: "/homework/makeSheet",
        query: {
          uuid: item.sheetUUID,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    uploadExamPaperDialog() {
      this.$refs.paperForm.init();
    },
    materialChange() {
      this.current = 1;
      this.materialId = this.materialIds.length ? this.materialIds[1] : null;
      this.getData();
      this.getBookInfo();
    },
    cancelShareItem(item) {
      const msg = `确定${
        item.isShare == 1 ? "取消分享" : "分享"
      }当前作业卡吗？`;
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await cancleShare({ sheetId: item.id });
          this.getData();
          this.$message({
            type: "success",
            message: "提交成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    onloadList() {
      // let { schoolPeriod } = getStore({ name: "userInfo" });
      // 搜索用的年级
      this.gradeOptionsCopy.push({ label: "全部", value: null, isClick: true });
      if (this.getLevelId.indexOf("3") != -1) {
        gradeHighOptions.forEach((i) => {
          i.isClick = false;
          this.gradeOptionsCopy.push(i);
        });
      }
      if (this.getLevelId.indexOf("2") != -1) {
        gradeMiddleOptions.forEach((i) => {
          i.isClick = false;
          this.gradeOptionsCopy.push(i);
        });
      }
      if (this.getLevelId.indexOf("1") != -1) {
        primaryOptions.forEach((i) => {
          i.isClick = false;
          this.gradeOptionsCopy.push(i);
        });
      }
    },
    async getgetTeachingBooksList() {
      const res = await getTeachingBooksList({
        levelId: this.getLevelId,
        subjectId: this.getSubjectId,
      });

      this.teachMaterialData = [];
      let array = [];
      res.data.data.forEach((index) => {
        if (array.length == 0 || array.indexOf(index.editionId) == -1) {
          array.push(index.editionId);
          this.teachMaterialData.push({
            label: index.editionName,
            value: index.id,
            editionId: index.editionId,
            children: [],
          });
        }
        if (array.indexOf[index.editionId] != -1) {
          this.teachMaterialData.forEach((item) => {
            if (item.editionId == index.editionId) {
              item.children.push({
                label:
                  index.gradeName +
                  index.editionName +
                  index.termName +
                  index.name,
                value: index.id,
              });
            }
          });
        }
      });
    },
    search() {
      this.current = 1;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getData();
    },
    searchBtn() {},
    gradeIdCliCK(item) {
      this.gradeId = item.value;

      this.current = 1;
      this.getData();
    },
    yearClick(year) {
      this.year = year.value;
      this.current = 1;
      this.getData();
    },
    getMaterialName(item) {
      const subject = this.subjectOptions.find(
        (it) => it.value == item.subjectId
      );
      return item.editionName + item.gradeName + subject.label + item.name;
    },
    async getBookInfo() {
      this.bookInfo = [];
      if (this.materialId) {
        const res = await getbookchapterbyid(this.materialId);
        this.getbookchapterList = [];
        this.getbookchapterData = res.data.data;
        let arrList = this.getbookchapterData;
        // 递归生成一个新树形节点
        arrList
          .filter((i) => i.rank == 1)
          .forEach((i) => {
            let obj = i;
            obj.children = this.convertToTreeData(arrList, i.number);
            this.getbookchapterList.push(obj);
          });
      }
    },
    //递归形成树形结构
    convertToTreeData(data, pid) {
      const result = [];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].number.slice(0, -1) == pid) {
          const obj = data[i];
          if (!obj.children) {
            obj.children = new Array();
          }
          temp = this.convertToTreeData(data, data[i].number);
          if (temp.length > 0) {
            obj.children = temp;
          } else {
            delete obj.children;
          }
          result.push(obj);
        }
      }
      // console.log(result);
      return result;
    },
    bookInfoChange() {
      this.current = 1;
      this.getData();
    },
    async getData() {
      try {
        this.loading = true;

        let chapterId = "";
        if (this.chapterIds.length > 0) {
          chapterId = this.chapterIds[this.chapterIds.length - 1];
        }
        const res = await resPage({
          size: this.pageSize,
          current: this.current,
          keyword: this.keyword,
          gradeId: this.gradeId,
          bookId: this.materialId,
          chapterId: chapterId,
          levelId: this.getLevelId,
          subjectId: this.getSubjectId,
          year: this.year,
          ifSelf: true,
        });
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    deleteItem(item) {
      this.$confirm("确定删除当前作业卡吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteMyHomework({ sheetId: item.id });
          if (this.current > 1 && this.tableData.length == 1) {
            this.current--;
          }
          this.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    menuClick(menu) {
      if (menu.type !== 4) {
        this.$router.push({
          path: "/res/myresource",
          query: { type: menu.type },
        });
      }
    },
  },
};
</script>
<style lang="scss">
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: $primary-color;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item:hover {
  color: $primary-color;
}
.el-dialog {
  max-width: 816px;

  .el-dialog__body {
    .form-item-box {
      // border-top: 1px solid #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .checkbox-item {
        width: 100%;
        // padding-left: 100px;
        margin-bottom: 8px;
      }
      .el-date-editor.el-input {
        width: 100%;
      }
      .item-box-btn {
        display: flex;
        justify-content: space-between;
        p {
          color: #2196f3;
          font-size: 14px;
          margin-right: 8px;
          margin-left: 8px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .el-select {
        width: 100%;
      }
      .el-form-item {
        width: 100%;
      }
    }
    .two-box {
      .el-form-item {
        width: 49%;
      }
    }
    .el-form-item {
      padding: 8px 0;
      margin-bottom: 0;
    }
  }
  .el-form-item__label {
    // text-align: left;
    margin-bottom: 0;
    white-space: nowrap;
  }
  // .el-input {
  //   input {
  //     background-color: #f5f5f5;
  //     border-color: #f5f5f5;
  //   }
  // }
}
// .el-select .el-input.is-focus .el-input__inner {
//   border-color: #f5f5f5;
// }
// .el-select .el-input__inner:focus {
//   border-color: #f5f5f5;
// }
// .el-select .el-input__inner:hover {
//   border-color: #f5f5f5;
// }
// .el-icon-arrow-up:before {
//   content: "\e78f";
// }
.el-select .el-input .el-select__caret {
  color: rgba(0, 0, 0, 0.5);
}
// .secrch-item {
//   width: 176px;
//   margin-bottom: 16px;
//   input {
//     // background-color: #f5f5f5;
//     border-color: #f5f5f5;
//   }
// }
.upload-button .el-upload .el-upload-dragger {
  width: 752px;
}
</style>

<style scoped lang="scss">
@import "./common.scss";
.resource-paper {
  width: 1220px;
  margin: 10px auto 50px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .right-box {
    .filter-box {
      border: 1px solid #e9e9e9;
      background: #f9fafc;
      box-sizing: border-box;
      padding: 18px;
      margin-bottom: 18px;
      .row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .item + .item {
          margin-left: 28px;
        }
      }

      .radios {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .label {
          margin-bottom: 16px;
        }
        .radio {
          min-width: 60px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #dee0e7;
          margin-bottom: 16px;
          cursor: pointer;
        }
        .radio + .radio {
          margin-left: 8px;
        }

        .active {
          border-color: #2474ed;
          color: #2474ed;
        }
      }
      .input {
        display: flex;
        align-items: center;
        .el-input {
          width: 220px;
        }
      }
      .item {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        .label {
          white-space: nowrap;
        }
      }
    }
    .homework-list {
      .homework {
        background: #ffffff;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        padding: 18px;
        &-title {
          color: #0a1119;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 10px;
          cursor: pointer;
          img {
            margin-right: 8px;
          }
        }
        &:hover {
          color: #2474ed;
          border-color: #2474ed;
          .homework-title {
            color: #2474ed;
          }
        }

        .footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .message {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .item {
              box-sizing: border-box;
              padding-right: 16px;
              color: #5e6a7a;
              font-size: 14px;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 10px;
              }
            }
            .item + .item {
              margin-left: 16px;
            }
          }
          .btns {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
      .homework + .homework {
        margin-top: 18px;
      }
    }
  }

  .upload-exam-paper-dialog {
    .upload-exam-paper-dialog-header {
      height: 30px;
      .filetype {
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;
      }
      .selected-filetype {
        color: #2474ed;
      }
    }
    .upload-button {
      width: 752px;
      .upload-text {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.upload-exam-paper-dialog {
  .upload-exam-paper-dialog-header {
    height: 30px;
    .filetype {
      display: inline-block;
      margin-right: 20px;
      cursor: pointer;
    }
    .selected-filetype {
      color: #2474ed;
    }
  }
  .upload-button {
    width: 752px;
    .upload-text {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
